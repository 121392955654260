html {
  font-size: 16px;
}

a {
  color: white;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

#root {
  background: rgb(10, 73, 142);
  background: linear-gradient(17deg,
      rgba(10, 73, 142, 1) 0%,
      rgba(10, 73, 142, 1) 74%,
      rgba(107, 116, 153, 1) 100%);
  height: 100%;
  width: 100%;
}

.App {
  font-family: "Segoe UI", sans-serif;
  text-align: center;
  font-size: 16px;
  max-width: 900px;
  margin: 0 auto;
}

.svgAnimation {
  font-size: 16rem;
  /* transform: rotateY(0); */
  animation: rotation 3s linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}